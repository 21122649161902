@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css");

.SignIn {
  /* background-image: url('../../assets/Building.jpg'); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.UserController {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin-top: 5vh;
}

.SignInContainer {
  /* background-image: url('../../assets/Building.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 70%;
  max-width: 150vw;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.SignInContainer .forms-container {
  position: relative;
  width: 50%;
  text-align: center;
}

.forms-container .form-control {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}

.forms-container .form-control h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  margin-top: -20px;
}

.forms-container form {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  align-items: center;
  justify-content: center;
}

.form-control form input {
  margin: 10px 0px;
  border: none;
  padding: 5px;
  /* background-color: rgba(12, 11, 11, 0.5); */
  background-color: transparent;
  border-bottom: 1px solid white;
  width: 75%;
}

.signup-form form input {
  width: 100%;
}
.signup-form .eye-input input{
  width: 100%;
}

.form-control span {
  margin: 10px 0px;
}
.checkbox {
  margin-bottom: 10px;
}

.checkbox label {
  cursor: pointer;
  font-weight: normal;
}

.checkbox input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0;
  background-color: transparent;
  border: 1px solid white;
}

.UserController input::placeholder{
  color: white;
  opacity: 0.7;
}

.form-control.signup-form {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  z-index: 1;
  left: 200%;
  background:#9b5bd4;
  border-radius: 5px;
  color: #f5f0f0;
  overflow-y: hidden;
}

.form-control.signin-form {
  opacity: 1;
  z-index: 2;
  left: 0%;
  background: #9b5bd4;
  border-radius: 5px;
  color: #f5f0f0;
  overflow-y: hidden;
}

.signin-form form .social {
  margin-bottom: 20px;
}

.SignInContainer .intros-container {
  position: relative;
  left: 50%;
  width: 50%;
  height: 100%;
  text-align: center;
}

.intros-container .intro-control {
  position: absolute;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.intro-control .intro-control__inner {
  width: 100%;
  /* margin: 0px 30px; */
  color: #e4e4e9;
  position: relative;
}

.intro-control h4 {
  display: flex;
  height: 100px;
  width: 10px;
  margin: 10px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  align-items: flex-end;
}

.intro-control.signin-intro {
  width: 100%;
  opacity: 1;
  z-index: 2;
  display: flex;
  align-items: flex-end;
}

.intro-control.signup-intro {
  opacity: 0;
  z-index: 1;
}

.change .signup-form {
  opacity: 1;
  z-index: 2;
  transform: translateX(-100%);
}

.signin-form button,
.signup-form button {
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 65%;
  font-weight: 600;
}
.signin-form button {
  width: 50%;
}

.change .signin-form {
  opacity: 0;
  z-index: 1;
  transform: translateX(-100%);
}

.signin-form .footer {
  position: absolute;
  bottom: 0%;
  width: 100%;
  text-align: center;
  background-color: #0a0a0a;
  color: #e4e4e9;
  padding: 10px;
  align-items: end;
}

.change .intro-control {
  transform: translateX(-100%);
}

.change .signin-btn {
  background-color: #eaf1ef;
  border-radius: 5px;
  color: #0a0a0a;
  align-items: end;
}

.change .signin-intro {
  opacity: 0;
  z-index: 1;
  /* height: 80vh; */
  display: flex;
  align-items: flex-end;
}

.change .signup-intro {
  opacity: 1;
  z-index: 2;
  align-items: flex-end;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .SignInContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .forms-container {
    order: 2;
    width: 100%;
    height: 50vh;
  }

  .form-control {
    position: absolute;
    height: 50vh;
  }

  .signup-form {
    left: 0%;
    margin-top: 70px;
  }

  .intros-container {
    order: 1;
    width: 100%;
    left: 0%;
    height: 40vh;
  }

  .intro-control {
    position: absolute;
    height: 40vh;
  }

  .change .signup-form {
    transform: translateX(0%);
  }

  .change .signin-form {
    transform: translateX(0%);
  }

  .change .intro-control {
    transform: translateX(0%);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .socials {
    bottom: -100%;
  }
  .socials1 {
    bottom: -100%;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  .socials {
    bottom: -50%;
  }
  .socials1 {
    bottom: -50%;
  }
  .intro-control .intro-control__inner {
    width: 200%;
  }
}

.FormMobile {
  color: white;
}
.FormMobile form input {
  margin: 10px 0px;
  border: none;
  padding: 5px;
  /* background-color: rgba(12, 11, 11, 0.5); */
  background-color: transparent;
  border-bottom: 1px solid white;
  width: 75%;
}
.FormMobile button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px;
  margin-top: 5px;
  cursor: pointer;
  width: 75%;
  font-weight: 600;
}
.FormMobile input::placeholder{
  color: white;
  opacity: 0.7;
}

.logineyeinput input {
  width: 100% !important;
}

/* .or {
  max-width: 200px;
  text-align: center;
  margin: auto;
  position: relative;
  font-size: 15px;
}

.or::before {
  content: "";
  width: 120px;
  height: 2px;
  color: #fff;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 30px;
}

.or::after {
  content: "";
  width: 120px;
  height: 2px;
  color: #fff;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
} */
