.categoryMain {
  /* background-image: url("../../assets/building_effect.png"); */
  /* background-position: bottom; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  position: relative;
}
.premiumMain{
  /* background-image: url("../../assets/building_effect.png"); */
  /* background-position: bottom; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}
.aboutMain {
  /* background-image: url("../../assets/building_effect.png"); */
  /* background-position: bottom; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  position: relative;
}

.contactMain {
  /* background-image: url("../../assets/building_effect.png"); */
  /* background-position: bottom; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  position: relative;
}
.PostPage {
  /* background-image: url("../../assets/building_effect.png"); */
  /* background-position: bottom; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* position: relative; */
}
