@import url("https://fonts.googleapis.com/css2?family=Asap:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Rowdies:wght@300;400;700&display=swap');


@font-face {
  font-family: "Archive";
  src: url("./assets/archive-regular-webfont.woff2");
}
@font-face {
  font-family: "ArchiveLight";
  src: url("./assets/archives-light.ttf");
}

@font-face {
  font-family: "Abhaya";
  src: url("./assets/AbhayaLibre-Medium-webfont.woff");
}

@font-face {
  font-family: "CyGroteskGrand";
  src: url("./assets/cy-grotesk-wide-medium.ttf");
}
@font-face {
  font-family: "LinikSans";
  src: url("./assets/LinikSans-SemiBold.ttf");
}

@font-face {
  font-family: "Cerebri";
  src: url("./assets/CerebriSansPro-Regular.ttf");
}

.FontLinikSans {
  font-family: "LinikSans", sans-serif;
}

.frauncesFont {
  font-family: "Fraunces", serif;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "SOFT" 0,
    "WONK" 0;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.public-sans-Stretch {
  font-family: "Public Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.montserrat-Stretch {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.rowdiesFont {
  font-family: "Rowdies", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Asap", sans-serif;
}
.CustomizeFontH {
  font-family: "Archive", "Asap", sans-serif;
}
.Font2 {
  font-family: "Abhaya", Helvetica, sans-serif;
}
.ButtonFont {
  font-family: "ArchiveLight", "Asap", "sans-serif";
}
.CerebriFont{
  font-family: "Cerebri" , sans-serif;
}

.YesevaFont{
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
}
.CormorantFont{
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: normal;
}


/* ...............css for the Subscription Page............... */

.Subscription {
  background-image: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.2) 30%,
      rgba(183, 136, 224, 0.557) 100%
    ),
    url("./assets/Building.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.premiumCard {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(183, 136, 224, 0.498)
  );
}
.pricingText {
  font-family: "CyGroteskGrand";
}
.pricingText p:first-child {
  letter-spacing: 5px;
}

/* ...............End of css for the Subscription Page............... */

/* ...............Styling of filter input range ............... */

/* For Chrome, Safari, and Opera */
.FilterSection input[type="range"]::-webkit-slider-thumb,
.Calculator input[type="range"]::-webkit-slider-thumb {
  background-color: #9747ff !important; /* Change thumb color */
  cursor: pointer;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

/* For Firefox */
.FilterSection input[type="range"]::-moz-range-thumb,
.Calculator input[type="range"]::-moz-range-thumb {
  background-color: #9747ff; /* Change thumb color */
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

/*--------------------Contact page map css-------------------- */
.map iframe {
  box-shadow: 20px 20px 10px 10px rgba(20, 13, 23, 0.2);
}

/* --------------------for cateogry page -> bug -------------------- */
.mobile-filter-drawer .bg-black {
  background-color: transparent !important;
}

/* --------------------waitlist modal otp input-------------------- */
.OtpField input {
  padding: 10px;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  margin: 10px;
  background-color: transparent;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  min-width: 3em;
}

.input-quiz::placeholder {
  color: #6e30a7;
}

.input-quiz::-ms-input-placeholder {
  /* Vendor prefix for IE */
  color: #6e30a7;
}
/* -------------------- stepper css used in waitlist modal-------------------- */
.step-item:not(:first-child):before {
  @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
}
.active .step {
  @apply bg-[#6e30a7];
}
.complete .step {
  @apply bg-[#6e30a7];
}
.complete p {
  @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-[#6e30a7];
  /* bg-green-600 */
}
/* -------------------- end of stepper css used in waitlist modal-------------------- */

.ToastSuccessBg{
  background-color: #b381e2 !important;
}
.ToastErrorBg{
  background-color: rgb(235, 120, 120) !important;
}

/* -------------------- customizing the checkbox and radio inputs -------------------- */

input[type="checkbox"] {
  accent-color: #b381e2 !important;
  border-radius: 2px;
  height: 15px;
  width: 15px;
}

input[type="checkbox"]:checked {
  accent-color: #b381e2 !important;
}

/* Customize radio button */
input[type="radio"] {
  accent-color: Mauve !important; 
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

input[type="radio"]:checked {
  accent-color: #b381e2 !important;
}
/* -------------------- end of customizing the checkbox and radio inputs -------------------- */

/* -------------------- removing border radius from IOS -------------------- */
input[type="text"],
input[type="email"],
input[type="password"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

.customizedTextInput {
  border-radius: 4px !important;
}
/* -------------------- end of removing border radius from IOS -------------------- */

/* --------------------styling of digit roll in Calculator-------------------- */
.roller .DigitRoll {
  color: white;
  border: none;
  overflow: hidden;
}

.roller .DigitRoll__Cell {
  margin: 0 -1px;
  width: 1.5rem !important;
}

.DigitRoll__Divider {
  padding: 0 -16px !important;
}

/* --------------------banner behaviour --------------------*/
.fixed-Bannertop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
}



/* --------------------start of gradient effect in profile page -------------------- */

.gradient-bg {
  background: linear-gradient(135deg, #8851BC 0%, #FFFFFF 95%, #FFFFFF 100%);
}

@media (max-width: 767px) {
  .gradient-bg {
    background: linear-gradient(135deg, #8851BC 0%, #FFFFFF 110%);
  }
}