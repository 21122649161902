body {
  /* background-image: linear-gradient(to right, #dcb7fc, #fbcfe8); */
}

.main {
  /* position: relative; */
  overflow-x: hidden;
  /* background-image: url("../../assets/building_effect.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainBgVideo{
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.SlideX {
  /* animation: spin; */
  animation-name: slideX;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  /* animation-direction: alternate; */
}
@keyframes slideX {
  25% {
    transform: translate(100px, 0);
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  55% {
    opacity: 1;
    transform: translate(0, 0px);
  }
  70% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.FlipY {
  animation-name: FlipY;
  animation-duration: 5s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

@keyframes FlipY {
  0% {
    transform: rotateY(0deg);
  }
  15% {
    transform: rotateY(90deg);
  }
  30% {
    transform: rotateY(180deg);
  }
  45% {
    transform: rotateY(90deg);
  }
  60% {
    transform: rotateY(0deg);
  }
}
.Move {
  animation-name: Transition;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}
@keyframes Transition {
  25% {
    transform: translate(0, 100px);
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: translate(0, -100px);
  }
  55% {
    opacity: 1;
    transform: translate(0, 0px);
  }
  70% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  /* overflow: hidden; */
  text-align: left;
}
.scroller > span {
  position: relative;
  animation: verticalSlider 8s infinite;
  /* font-weight: bold; */
  background-color: transparent;
}
@keyframes verticalSlider {
  0% {
    top: 0;
  }
  16.7% {
    top: -1.2em;
  }
  33.4% {
    top: -2.4em;
  }
  50.1% {
    top: -3.6em;
  }
  66.8% {
    top: -4.8em;
  }
  83.5% {
    top: -6em;
  }
  100% {
    top: -7.2em;
  }
}

/* -----------------------------this css is only to fixed the scroller text overflow with a particaular device width size----------------------------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .scroller {
    z-index: -1;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 375px) and (max-device-width: 667px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 414px) and (max-device-width: 736px) {
  .scroller {
    z-index: -1;
  }
}
/* -----------------------------end of this css is only to fixed the scroller text overflow with a particaular device width size----------------------------- */

/* -----------------------------this css is to fix all the issue/bugs in the iPad -----------------------------*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1124px) and (orientation: portrait),
only screen and (min-device-width: 1024px) and (max-device-width: 1124px) and (orientation: landscape) {
  .iPadHeight{
    height: 430px;
    margin-left: 80px;
  }
  .iPadAlignment{
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .iPadHeadingBreaking{
    white-space: nowrap;
    text-wrap: nowrap;
  }
  .iPadWdth{
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait),
only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) {
  .iPadAlignment{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 0px !important;
  }
  .iPadWdth{
    width: 70%;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 1024px) and (max-device-width: 1124px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 1024px) and (max-device-width: 1124px) {
  .iPadHeight{
    height: 430px;
  }
  .iPadAlignment{
    margin-left: 5rem;
    margin-right: 0rem;
  }
  .iPadHeadingBreaking{
    white-space: nowrap;
    text-wrap: nowrap;
  }
  .iPadWdth{
    width: 100%;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 768px) and (max-device-width: 1023px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 768px) and (max-device-width: 1023px) {
  .iPadAlignment{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: -30px !important;
  }
  .iPadWdth{
    width: 70%;
  }
}

/* -----------------------------end of this css is to fix all the issue/bugs in the iPad----------------------------- */