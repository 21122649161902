
.ButtonSection::-webkit-scrollbar{
  height: 10px;
  border-radius: 10px;
  visibility: hidden;
}
.ButtonSection::-webkit-scrollbar-track{
  background-color: #dadada;
  visibility: hidden;
  border-radius: 10px;
  
}
.ButtonSection::-webkit-scrollbar-thumb{
  background-color: #b0b0b0;
  visibility: hidden;
  border-radius: 5px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1124px) and (orientation: portrait),
only screen and (min-device-width: 768px) and (max-device-width: 1124px) and (orientation: landscape) {
  .ipad-margin-top {
    margin-top: 8px !important; 
  }
  .iPadPostSize{
    height: 100%;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 768px) and (max-device-width: 1124px),
       only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 768px) and (max-device-width: 1124px) {
        .ipad-margin-top {
          margin-top: 8px !important; 
        }
      .iPadPostSize{
        height: 100%;
      }
}

.CompareInput input[type="checkbox"] {
  width: 18px;
  height: 18px;
}