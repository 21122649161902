:root {
  --accent-color: #6e30a7;
}

.quiz-container {
  font-family: "Poppins", sans-serif;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  /* height: 100vh; */
  overflow: hidden;
  /* background-color: #fff; */
  border-radius: 20px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}


.loading-bar {
  width: 100%;
  height: 5px;
  /* background-color: #eee; */
  border-radius: 5px;
  overflow: hidden;
}

.loading-bar-progress {
  background-color: var(--accent-color);
  /* height: 100%; */
  width: 0;
  transition: 0.3s ease;
}

h2 {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

.quiz-container ul li {
  /* font-size: 1.2rem; */
  margin: 1rem 0;
}

ul li input {
  display: none;
}

ul li label {
  cursor: pointer;
  display: block;
  color: rgb(35, 35, 35);
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.1s ease;
}

ul li input:hover + label {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

ul li input:checked + label {
  outline: 2px solid var(--accent-color);
  color: var(--accent-color);
}

.quiz-container button {
  color: var(--accent-color);
  background-color: transparent;
  font-family: inherit;
  border: none;
  display: block;
  cursor: pointer;
  margin: auto;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1rem;
  transition: 0.1s ease;
}

.quiz-container button:hover {
  transform: translateY(-3px);
}

.quiz-container button:active {
  transform: translateY(2px);
}
